<template>
  <div
    class="o-header"
    :style="!defaultPage ? 'backdrop-filter: blur(1px);' : ''"
  >
    <!-- <ALogo class="desktop-only" :logo="defaultPage ? 'dark' : 'white'" /> -->
    <ALogo :logo="'dark'" />
    <div class="o-header__menu desktop-only">
      <div v-for="(item, i) in menu" :key="i" class="o-header__menu--item">
        <span @click="item.link ? goTo(item.link) : goToHash(item.hash)">{{
          item.title
        }}</span>
        <div v-if="item.children" class="o-header__menu--children">
          <div
            v-for="project in item.children"
            :key="project.slug"
            class="o-header__menu--children-item project"
            @click.stop="goToProject(project.slug)"
          >
            <div class="project__image">
              <img :src="project.image" />
            </div>
            <div class="project__info">
              <h4 class="project__info--title">{{ project.title }}</h4>
              <div class="project__info--details">
                <div class="project__info--details-item">
                  <SfIcon
                    size=".875rem"
                    icon="marker"
                    color="#7f828b"
                    aria-hidden="true"
                    class="sf-arrow__icon buttonArrow"
                  />
                  <span>{{ project.location }}</span>
                </div>
                <div class="project__info--details-item">
                  <SfIcon
                    size=".875rem"
                    icon="clock"
                    color="#7f828b"
                    aria-hidden="true"
                    class="sf-arrow__icon buttonArrow"
                  />
                  <span>{{ project.deadline }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-only">
      <SfIcon
        size="2rem"
        icon="list"
        color="#131112"
        aria-hidden="true"
        class="sf-arrow__icon icon-reverse"
        @click="isMenuOpen = true"
      />
      <div class="mobile-menu" :class="{ 'mobile-menu__open': isMenuOpen }">
        <div class="mobile-menu__header">
          <div class="mobile-menu__header--logo">
            <img src="@/assets/logos/s1_bw2_logo.png" />
          </div>
          <div class="mobile-menu__header--close">
            <SfIcon
              size="2rem"
              icon="cross"
              color="#f5f1ed"
              aria-hidden="true"
              class="sf-arrow__icon"
              @click="isMenuOpen = false"
            />
          </div>
        </div>
        <div class="mobile-menu__container">
          <div
            v-for="(item, i) in menu"
            :key="i"
            @click="
              item.link
                ? (goTo(item.link), (isMenuOpen = false))
                : (goToHash(item.hash), (isMenuOpen = false))
            "
            class="mobile-menu__item"
          >
            {{ item.title }}
          </div>
          <!-- <div class="mobile-menu__item">Proiect</div>
          <div class="mobile-menu__item">Galerie</div>
          <div class="mobile-menu__item">Contact</div> -->
        </div>
        <div class="mobile-menu__contact">
          <a href="tel:+40731789123">
            <SfIcon
              size="2rem"
              icon="phone"
              color="#131112"
              aria-hidden="true"
              class="sf-arrow__icon"
            />
          </a>
          <a href="mailto:contact@residom.ro">
            <SfIcon
              size="2rem"
              icon="mail"
              color="#131112"
              aria-hidden="true"
              class="sf-arrow__icon"
            />
          </a>
          <a href="https://maps.app.goo.gl/4QuavAJUQjSGSGUi7" target="_blank">
            <SfIcon
              size="2rem"
              icon="marker"
              color="#131112"
              aria-hidden="true"
              class="sf-arrow__icon"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ALogo from "@/components/atoms/a-logo.vue";
import { SfIcon } from "@storefront-ui/vue";

export default {
  components: {
    ALogo,
    SfIcon,
  },
  data() {
    return {
      isMenuOpen: false,
      menu: [
        {
          title: "Acasă",
          link: "/",
        },
        {
          title: "Proiect",
          link: "/proiecte/bod",
        },
        // CHILDREN MENU (should be used for multiple )
        // {
        //   title: "Proiecte",
        //   link: "/proiecte",
        //   children: [
        //     {
        //       title: "Project Bod1",
        //       slug: "bod1",
        //       location: "Bod, Brasov",
        //       deadline: "Noiembrie 2024",
        //       image: require("@/assets/photos/photo1.jpg"),
        //     },
        //     {
        //       title: "Project Bod2",
        //       slug: "bod2",
        //       location: "Bod, Brasov",
        //       deadline: "Noiembrie 2024",
        //       image: require("@/assets/photos/photo1.jpg"),
        //     },
        //     {
        //       title: "Project Bod3",
        //       slug: "bod3",
        //       location: "Bod, Brasov",
        //       deadline: "Noiembrie 2024",
        //       image: require("@/assets/photos/photo1.jpg"),
        //     },
        //   ],
        // },
        {
          title: "Galerie",
          hash: "#galerie-bod",
        },
        {
          title: "Contact",
          link: "/contact",
        },
      ],
    };
  },
  // mounted() {
  //   console.log(this.$route.name);
  //   console.log(this.$route.name || this.$route.name === "Home");
  // },
  computed: {
    defaultPage() {
      return this.$route.name !== "Contact";
      // return true;
    },
  },
  methods: {
    goTo(link) {
      this.$router.push(link);
    },
    goToProject(slug) {
      this.$router.push({ name: "Project", params: { slug: slug } });
    },
    goToHash(hash) {
      this.$router.push(hash);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.o-header {
  z-index: 1;
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 10rem);
  // max-width: var(--header-width);
  margin: 0 auto;
  padding: var(--spacer-xs) var(--spacer-2xl);
  align-items: center;
  @include for-mobile {
    padding: var(--spacer-xs) var(--spacer-sm);
    width: calc(100% - 2rem);
  }

  &__menu {
    display: flex;
    color: var(--c-dark);
    &--item {
      position: relative;
      text-transform: uppercase;
      margin-left: var(--spacer-base);
      font-size: var(--font-sm);
      font-weight: var(--font-semibold);
      letter-spacing: 1px;
      line-height: 1.4;
      cursor: pointer;

      background-image: linear-gradient(
        var(--c-primary-darken),
        var(--c-primary-darken)
      );
      background-size: 0% 0.1em;
      background-position-y: 100%;
      background-position-x: 100%;
      background-repeat: no-repeat;
      transition: background-size 0.35s ease-in-out, color 0.35s ease-in-out;
      &:hover {
        background-size: 100% 0.1em;
        background-position-x: 0%;
        color: var(--c-primary-darken);

        .o-header__menu--children {
          opacity: 1;
          height: 23rem;
        }
      }
    }
    &--children {
      position: absolute;
      min-width: 20vw;
      left: 0;
      background: var(--c-light);
      color: var(--c-text);
      opacity: 0;
      overflow: hidden;
      height: 0;
      transition: all 0.3s ease-in-out;
      &-item {
        margin: var(--spacer-sm) var(--spacer-sm);
        padding: 0 0 var(--spacer-sm);
        &:not(:last-child) {
          border: 1px solid;
          border-width: 0 0 1px 0;
          border-color: var(--c-light-darken);
        }
        &:first-child {
          padding-top: var(--spacer-sm);
        }
      }
    }
  }
}

.project {
  display: flex;
  align-items: center;
  &:hover {
    .project__info--title {
      text-decoration: underline;
    }
    .project__image img {
      transform: scale(1.1);
    }
  }
  &__image {
    width: 7rem;
    height: 5rem;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
    }
  }
  &__info {
    margin-left: var(--spacer-sm);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 5rem;
    &--title {
      margin: 0;
      font-size: var(--font-base);
      font-family: var(--font-family-primary);
    }
    &--details {
      &-item {
        display: flex;
        align-items: center;
        margin: 0.375rem 0 0;
        span {
          margin-left: var(--spacer-2xs);
          font-size: var(--font-2xs);
          line-height: 1;
          text-transform: none;
          font-weight: var(--font-normal);
          color: var(--c-gray-lighten);
        }
      }
    }
  }
}
.icon-reverse {
  transform: rotate(180deg);
}

.mobile-menu {
  filter: drop-shadow(1px 1px 20px black);
  background-color: var(--c-light);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  // border-radius: 0 0 10px 10px;
  &__contact {
    position: absolute;
    bottom: var(--spacer-xl);
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: calc(100% - 2rem);
    padding: 0 var(--spacer-sm);
    background-color: var(--c-light);
  }
  &__open {
    height: 29rem;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    background-color: var(--c-primary);
    height: 4rem;
    width: fit-content;
    padding: var(--spacer-xs) var(--spacer-sm);
    width: calc(100% - 2rem);
    margin-bottom: var(--spacer-lg);
    cursor: pointer;
    img {
      width: auto;
      height: 4rem;
      user-select: none;
    }
    &--close {
      display: flex;
      align-items: center;
      height: 4rem;
    }
  }
  &__item {
    @include for-mobile {
      padding: 0.75rem var(--spacer-sm);
    }
    text-align: right;
    font-size: var(--font-lg);
    text-transform: uppercase;
    padding: var(--spacer-sm);
    border-bottom: 2px solid var(--c-light-darken);
    &:not(:last-child) {
      margin-bottom: var(--spacer-sm);
    }
  }
}
</style>
