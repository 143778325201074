<template>
  <div class="o-footer">
    <div class="logo">
      <img src="@/assets/logos/s2_bw1_logo.png" width="100" />
    </div>
    <div class="menu">
      <span @click="$router.push('/')">Acasă</span>
      <span @click="$router.push('/proiecte/bod')">Proiect</span>
      <span @click="$router.push('/contact')">Contact</span>
    </div>
    <div class="menu">
      <span @click="$router.push('/politica-de-confidentialitate')">
        Politică de confidențialitate
      </span>
    </div>
    <div class="info">
      <p>
        Locație: Str. Brândușelor nr. 74, Brașov, România &#9866; Telefon: 0731
        789 123 / 0731 123 444 &#9866; Email: contact@residom.ro
      </p>
      <p>Copyright &copy; 2024 Residom.ro &#9866; Toate drepturile rezervate</p>
    </div>
    <div class="bottom">
      <p>
        Proudly created by
        <a href="https://www.linkedin.com/in/laurentiu-tutu" target="_blank"
          >Laurentiu Tutu</a
        >.
      </p>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.o-footer {
  width: 100%;
  background-color: var(--c-dark);
  padding: var(--spacer-2xl) 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.menu {
  color: var(--c-light);
  display: flex;
  justify-content: center;
  margin-top: var(--spacer-base);
  span {
    display: block;
    font-size: var(--font-sm);
    margin: 0 var(--spacer-lg);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.info {
  @include for-mobile {
    margin: var(--spacer-2xl) var(--spacer-base) 0;
  }
  color: var(--c-light);
  font-size: var(--font-sm);
  text-align: center;
  margin-top: var(--spacer-2xl);
  p {
    margin: 0 0 var(--spacer-xs);
    &:last-child {
      margin: 0;
    }
  }
}
.logo {
  img {
    filter: invert(99%) sepia(67%) saturate(213%) hue-rotate(297deg)
      brightness(102%) contrast(92%);
  }
}
.bottom {
  width: 100%;
  background-color: var(--c-dark-lighten);
  color: var(--c-gray);
  margin: var(--spacer-base) 0 0;
  p {
    padding: var(--spacer-xs) 0;
    margin: 0;
    font-size: var(--font-xs);
    text-align: center;

    a {
      color: var(--c-primary);
      font-weight: var(--font-normal);
      text-decoration: none;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
