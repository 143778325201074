import Vue from "vue";
import VueRouter from "vue-router";

const HomeView = () =>
  import(/* webpackChunkName: "vsf-home" */ "../views/HomeView.vue");
// const AboutView = () =>
//   import(/* webpackChunkName: "vsf-about" */ "../views/AboutView.vue");
// const ProjectsView = () =>
//   import(/* webpackChunkName: "vsf-projects" */ "../views/ProjectsView.vue");
const ProjectView = () =>
  import(/* webpackChunkName: "vsf-project" */ "../views/ProjectView.vue");
// const GalleryView = () =>
//   import(/* webpackChunkName: "vsf-gallery" */ "../views/GalleryView.vue");
const ContactView = () =>
  import(/* webpackChunkName: "vsf-contact" */ "../views/ContactView.vue");
const ContactSuccessView = () =>
  import(
    /* webpackChunkName: "vsf-contact-success" */ "../views/ContactSuccessView.vue"
  );
const PrivacyPolicyView = () =>
  import(
    /* webpackChunkName: "vsf-privacy-policy" */ "../views/PrivacyPolicyView.vue"
  );
const PageNotFound = () =>
  import(
    /* webpackChunkName: "vsf-page-not-found" */ "../views/PageNotFound.vue"
  );

Vue.use(VueRouter);

const routes = [
  { name: "Home", path: "/", component: HomeView },
  // { name: "About", path: "/about", component: AboutView },
  // { name: "Projects", path: "/proiecte", component: ProjectsView },
  { name: "Project", path: "/proiecte/:slug", component: ProjectView },
  // { name: "Gallery", path: "/galerie", component: GalleryView },
  { name: "Contact", path: "/contact", component: ContactView },
  { name: "ContactSuccess", path: "/succes", component: ContactSuccessView },
  {
    name: "PrivacyPolicy",
    path: "/politica-de-confidentialitate",
    component: PrivacyPolicyView,
  },
  { name: "PageNotFound", path: "*", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
