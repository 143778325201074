<template>
  <div id="app">
    <MNotification />
    <OHeader />
    <MStructuredGallery
      v-if="isStructuredGalleryActive || galleryActive"
      :class="{
        'hidden-gallery-main': !isStructuredGalleryActive || !galleryActive,
        'open-gallery-main': galleryActive,
      }"
      :gallery="getGalleryByLink.gallery.all"
      :albumTitle="getGalleryByLink.albumTitle"
      :galleryInfo="getGalleryByLink.galleryInfo"
      :galleryNote="getGalleryByLink.galleryNote"
    />
    <router-view />
    <OFooter />
  </div>
</template>

<script>
import MNotification from "@/components/molecules/m-notification.vue";
import OHeader from "@/components/organisms/o-header.vue";
const project = require("@/resources/project-bod.json");
import MStructuredGallery from "./components/molecules/m-structured-gallery.vue";
import OFooter from "@/components/organisms/o-footer.vue";

export default {
  components: {
    OHeader,
    OFooter,
    MStructuredGallery,
    MNotification,
  },
  data() {
    return {
      scrollbarWidth: 0,
      isStructuredGalleryActive: false,
      galleryActive: false,
      galleryNote:
        "Mobilierul, echipamentele și elementele de decor sunt doar cu titlu de prezentare.",
    };
  },
  mounted() {
    this.getScrollbarWidth();
  },
  watch: {
    $route(to, from) {
      if (to?.hash == "#galerie-bod" || to?.hash == "#progres-santier") {
        this.toggleStructuredGallery();
      }
      if (from?.hash == "#galerie-bod" || from?.hash == "#progres-santier") {
        this.toggleStructuredGallery();
      }
    },
  },
  computed: {
    getGalleryByLink() {
      const route = this.$route;
      if (route.name === "Project" && route.hash === "#progres-santier") {
        return {
          gallery: project.progress_gallery,
          albumTitle: "Stagii santier",
          galleryInfo: false,
        };
      } else {
        return {
          gallery: project.gallery,
          albumTitle: "Tur foto",
          galleryNote: this.galleryNote,
          galleryInfo: true,
        };
      }
    },
  },
  methods: {
    toggleStructuredGallery() {
      this.isStructuredGalleryActive = !this.isStructuredGalleryActive;
      this.$emit("toggleGallery", this.isStructuredGalleryActive);
      setTimeout(() => {
        this.galleryActive = !this.galleryActive;
        if (this.isStructuredGalleryActive) {
          document.body.classList.add("open-gallery-main");
          document.body.style.paddingRight = `${this.scrollbarWidth}px`;
        } else {
          document.body.classList.remove("open-gallery-main");
          document.body.style.paddingRight = 0;
        }
      }, 500);
    },
    getScrollbarWidth() {
      // Create a div with a scrollbar
      const scrollDiv = document.createElement("div");
      scrollDiv.style.width = "100px";
      scrollDiv.style.height = "100px";
      scrollDiv.style.overflow = "scroll";
      scrollDiv.style.position = "absolute";
      scrollDiv.style.top = "-9999px";
      scrollDiv.style.left = "-9999px";
      document.body.appendChild(scrollDiv);

      // Calculate the scrollbar width
      this.scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      // Remove the div
      document.body.removeChild(scrollDiv);
    },
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
}
.open-gallery-main {
  overflow: hidden;
  background-color: var(--c-light);
  top: 0;
}
#app {
  font-family: var(--font-family-secondary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--c-dark);
  background-color: var(--c-light);

  ::selection {
    color: var(--c-light);
    background: var(--c-primary-darken);
  }

  .hidden-gallery-main {
    opacity: 0;
    top: 100%;
    &::v-deep .structuredGallery {
      overflow-y: hidden !important;
    }
  }
}

nav {
  a {
    font-weight: bold;
    color: var(--c-dark);

    &.router-link-exact-active {
      color: var(--c-primary);
    }
  }
}
</style>
