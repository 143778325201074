<template>
  <div class="m-structured-gallery">
    <div class="navbar">
      <div class="navbar__backAction" @click="hideGallery">
        <div class="navbar__backAction--icon">
          <SfIcon
            size="1.1rem"
            icon="chevron_left"
            color="#131112"
            aria-hidden="true"
            class="sf-arrow__icon"
          />
        </div>
        <span class="navbar__backAction--label">Înapoi</span>
      </div>
    </div>
    <div class="structuredGallery">
      <div class="structuredGallery__inner">
        <h4
          class="structuredGallery__categories--title"
          ref="structuredGallery"
        >
          {{ albumTitle }}
        </h4>
        <div class="structuredGallery__categories">
          <div
            v-for="room in formattedGallery"
            :key="room.id"
            class="structuredGallery__categories--category"
            @click="jumpToRoom(room.id)"
          >
            <div class="structuredGallery__categories--category-image">
              <img :src="getGalleryImage(room.id, room.hero)" :alt="room.id" />
            </div>
            <div class="structuredGallery__categories--category-label">
              <span>{{ room.label }}</span>
            </div>
          </div>
        </div>
        <div
          v-for="room in formattedGallery"
          :key="room.id"
          :ref="room.id"
          class="structuredGallery__container"
        >
          <div class="structuredGallery__container--info">
            <div class="room-info">
              <span v-if="galleryNote" class="room-info__badge">
                <SfIcon
                  size="1.1rem"
                  icon="info_circle"
                  color="#eca400"
                  aria-hidden="true"
                  class="sf-arrow__icon room-info__badge--icon"
                />
                {{ galleryNote }}
              </span>
              <h4>{{ room.label }}</h4>
              <ul v-if="room.highlights" class="room-info__highlights">
                <li
                  v-for="(highlight, i) in room.highlights"
                  :key="highlight"
                  class="room-info__highlights--highlight"
                >
                  <span>{{ highlight }}</span>
                  <span
                    class="separator"
                    v-if="room.highlights.length !== i + 1"
                    >·</span
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="structuredGallery__container--photos">
            <!-- <div class="photo-container">
              <img :src="getGalleryImage(room.id, room.photos[0])" />
            </div> -->
            <AGalleryGrid
              v-for="(grid, i) in room.photos"
              :key="`${room.id}-grid-${i}`"
              :images="grid"
              @select-image="setCurrentImage"
            />
          </div>
        </div>
      </div>
    </div>
    <MSwipeGallery
      :class="{
        'hidden-gallery': !isSwipeGalleryActive,
        'open-gallery': isSwipeGalleryActive,
      }"
      :info="galleryInfo"
      :images="getAllPhotos"
      :selected="selectedImage"
      @close="isSwipeGalleryActive = false"
    />
  </div>
</template>
<script>
import { SfIcon } from "@storefront-ui/vue";
import AGalleryGrid from "@/components/atoms/a-gallery-grid.vue";
import MSwipeGallery from "@/components/molecules/m-swipe-gallery.vue";

export default {
  components: {
    SfIcon,
    AGalleryGrid,
    MSwipeGallery,
  },
  props: {
    gallery: {
      type: Array,
      default: () => [],
    },
    albumTitle: {
      type: String,
      default: "Tur foto",
    },
    galleryNote: {
      type: String,
      default: "",
    },
    galleryInfo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSwipeGalleryActive: false,
      selectedImage: "",
    };
  },
  computed: {
    getAllPhotos() {
      let requiredGallery = [];
      this.gallery.forEach((room) => {
        let asdf = Array.from({ length: room.photos }, (_, index) => {
          return {
            id: room.id,
            room: room.label,
            ...(room.photosDescription && {
              feature: room.photosDescription[index],
            }),
            photo: `${room.id}-photo-${index + 1}`,
          };
        });
        requiredGallery = requiredGallery.concat(asdf);
      });
      return requiredGallery;
    },
    formattedGallery() {
      let retrievedGallery = [];
      this.gallery.forEach((room) => {
        let newRoom = {
          id: room.id,
          label: room.label,
          highlights: room.highlights,
          hero: `${room.id}-photo-featured`,
          photos: this.gridLayoutGenerator(room.id, room.photos),
        };
        retrievedGallery.push(newRoom);
      });
      return retrievedGallery;
    },
  },
  methods: {
    toggleSwipeGallery(image) {
      this.isSwipeGalleryActive = !this.isSwipeGalleryActive;
      this.selectedImage = image;
    },
    setCurrentImage(image) {
      this.toggleSwipeGallery(image);
    },
    hideGallery() {
      this.$router.push(this.$route.path);
      this.$emit("back");

      setTimeout(() => {
        this.$refs["structuredGallery"].scrollIntoView();
      }, 500);
    },
    jumpToRoom(room) {
      const [el] = this.$refs[room];
      if (el) {
        el.scrollIntoView({ behavior: "smooth" });
      }
    },
    getGalleryImage(folder, image) {
      if (image) {
        return require(`@/assets/gallery/${folder}/${image}-webp.webp`);
      }
    },
    gridLayoutGenerator(room, total) {
      let result = [1];
      let sum = 1;
      let maxNumber = 3;

      while (sum < total) {
        if (result[result.length - 1] === 1 && sum + 1 === total) {
          result[result.length - 1] = 2;
          sum = sum + 1;
        } else {
          let random = Math.floor(Math.random() * maxNumber) + 1;
          if (
            maxNumber < 1 ||
            (random === 1 && random === result[result.length - 1])
          ) {
            maxNumber = 3;
          } else {
            if (sum + random <= total) {
              sum = sum + random;
              result.push(random);
              maxNumber = maxNumber - 1;
            }
          }
        }
      }
      let counter = 0;
      let grid = result.map((element) => {
        let photoGrid = [];
        for (let i = 0; i < element; i++) {
          counter++;
          photoGrid.push({
            room: room,
            name: `${room}-photo-${counter}`,
          });
        }
        return photoGrid;
      });
      return grid;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-structured-gallery {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: var(--c-light);
  transition: all 0.3s ease-in-out;
}
.navbar {
  @include for-mobile {
    padding: 0 var(--spacer-sm);
    width: calc(100% - 2rem);
    top: 0;
    height: 4rem;
    z-index: 5;
  }
  background-color: var(--c-light);
  position: fixed;
  width: calc(100% - 10rem);
  height: 5rem;
  padding: 0 var(--spacer-2xl);
  display: flex;
  align-items: center;
  &__backAction {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      .navbar__backAction--icon {
        background-color: var(--c-light-darken);
      }
      .navbar__backAction--label {
        border-color: var(--c-dark);
      }
    }
    &--icon {
      transition: all 0.15s ease-in-out;
      padding: var(--spacer-xs);
      border-radius: 50%;
    }
    &--label {
      font-size: var(--font-base);
      font-weight: var(--font-bold);
      text-transform: uppercase;
      line-height: 1;
      margin-left: var(--spacer-sm);
      transition: all 0.15s ease-in-out;
      border-bottom: 1px solid;
      border-color: var(--c-light);
    }
  }
}
.structuredGallery {
  @include for-mobile {
    padding: var(--spacer-lg) var(--spacer-base);
    height: calc(100vh - 4rem - 4rem);
    margin: 4rem 0 0;
  }
  height: calc(100vh - 5rem - 5rem);
  overflow-y: auto;
  padding: var(--spacer-xl) var(--spacer-2xl);
  margin: 5rem 0 0;
  &__inner {
    max-width: 1200px;
    margin: 0 auto;
  }
  &__categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include for-mobile {
      flex-wrap: nowrap;
      overflow-x: scroll;
    }
    &--title {
      font-size: var(--font-xl);
      font-weight: var(--font-bold);
      margin: 0 0 var(--spacer-lg);
    }
    &--category {
      @include for-mobile {
        // flex: 1 1 calc(50% - 0.75rem);
        // max-width: calc(50% - 0.75rem);
        min-width: 44%;
        max-width: 44%;
        margin: 0 0.75rem 0.75rem 0;
        &:last-child {
          margin: 0 0 0.75rem 0;
        }
      }
      flex: 1 1 calc(20% - 0.75rem);
      max-width: calc(20% - 0.75rem);
      cursor: pointer;
      &:hover {
        .structuredGallery__categories--category-image {
          opacity: 0.85;
        }
      }
      &-image {
        width: 100%;
        height: 9rem;
        box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
        transition: all 0.15s ease-in-out;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      &-label {
        margin-top: var(--spacer-xs);
        span {
          font-size: var(--font-sm);
          font-weight: var(--font-normal);
        }
      }
    }
  }
  &__container {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    @include for-mobile {
      margin-top: 3rem;
    }
    &--info {
      @include for-mobile {
        flex: 1 1 100%;
        max-width: 100%;
      }
      flex: 1 1 35%;
      max-width: 35%;
    }
    &--photos {
      @include for-mobile {
        flex: 1 1 100%;
        max-width: 100%;
      }
      flex: 1 1 65%;
      max-width: 65%;
    }
  }
}
.room-info {
  position: sticky;
  top: var(--spacer-2xs);
  @include for-desktop {
    height: 6rem;
  }
  &__highlights {
    @include for-mobile {
      max-width: 100%;
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    max-width: 90%;
    &--highlight {
      font-size: var(--font-sm);
      color: var(--c-gray-lighten);
      text-wrap: nowrap;
      list-style: none;
    }
  }
  h4 {
    margin: 0 0 var(--spacer-sm);
    font-size: var(--font-xl);
    font-weight: var(--font-bold);
  }
  &__badge {
    display: flex;
    width: fit-content;
    border-radius: 50px;
    font-weight: var(--font-medium);
    margin-bottom: var(--spacer-xs);
    font-size: var(--font-xs);
    color: var(--c-gray-lighten);
    max-width: 75%;
    &--icon {
      margin-right: var(--spacer-xs);
    }
    @include for-mobile {
      max-width: 100%;
    }
  }
}
.photo-container {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.separator {
  margin: 0 var(--spacer-2xs);
}
.hidden-gallery {
  opacity: 0;
  bottom: -100%;
  &::v-deep .structuredGallery {
    overflow-y: hidden !important;
  }
}
</style>
