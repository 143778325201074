<template>
  <div class="m-swipe-gallery" tabindex="0" @keydown.esc="$emit('close')">
    <div class="navbar">
      <div class="close-btn" @click="$emit('close')">
        <div class="close-btn__icon">
          <SfIcon
            size="1.05rem"
            icon="cross"
            color="#f5f1ed"
            aria-hidden="true"
            class="sf-arrow__icon"
          />
        </div>
        <span class="desktop-only close-btn__text">Închide</span>
      </div>
      <span class="counter">{{
        `${images.indexOf(activeSlide) + 1} / ${images.length}`
      }}</span>
      <div class="dummy" />
    </div>
    <SfCarousel
      class="carousel"
      v-if="images.length > 1"
      :key="carouselSettings.startAt"
      :settings="carouselSettings"
      @move="getCurrentSlide"
    >
      <SfCarouselItem
        v-for="image in images"
        :key="image.photo"
        class="carouselItem"
      >
        <div class="container" :key="image.photo" :class="image.photo">
          <span v-if="info" class="infoNote">
            <SfIcon
              size="1.05rem"
              icon="info_circle"
              color="#f5f1ed"
              aria-hidden="true"
              class="sf-arrow__icon infoNote__icon"
            />
            Amenajare cu titlu de prezentare
          </span>
          <img
            :src="getImage(image.photo, image.id)"
            :alt="image.photo"
            class="container__image"
          />
        </div>
      </SfCarouselItem>
    </SfCarousel>
    <div class="image-name">
      <p>
        {{ activeSlide ? activeSlide.room : ""
        }}<b v-if="activeSlide.feature"> - {{ activeSlide.feature }}</b>
      </p>
    </div>
  </div>
</template>
<script>
import { SfIcon, SfCarousel } from "@storefront-ui/vue";

export default {
  components: {
    SfIcon,
    SfCarousel,
  },
  props: {
    images: {
      type: Array,
      default: () => [],
      required: true,
    },
    selected: {
      type: String,
      default: "",
    },
    info: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeSlide: "",
      selectedImage: "",
      carouselSettings: {
        startAt: 0,
        animationDuration: 500,
        rewind: true,
        slidePerPage: false,
        perView: 1,
        peek: {
          before: 0,
          after: 0,
        },
        breakpoints: {
          1000: {
            perView: 1,
            peek: {
              before: 0,
              after: 0,
            },
          },
        },
      },
    };
  },
  watch: {
    selected() {
      this.selectedImage = this.selected;
      this.currentImageIndex();
    },
  },
  methods: {
    currentImageIndex() {
      const currentImage = this.images.findIndex(
        (photo) => photo.photo === this.selectedImage
      );
      this.carouselSettings.startAt = currentImage;
      return currentImage;
    },
    getCurrentSlide(index) {
      this.activeSlide = this.images[index];
    },
    getImage(image, folder) {
      if (image) {
        return require(`@/assets/gallery/${folder}/${image}-webp.webp`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-swipe-gallery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: calc(100vw - 10rem);
  height: 100%;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: var(--c-dark);
  color: var(--c-light);
  transition: all 0.3s ease-in-out;
  padding: 0 var(--spacer-2xl);
  user-select: none;
  @include for-mobile {
    padding: 0;
    width: 100%;
  }
  .carousel {
    width: calc(100vw - 10rem);
    height: 100vh;
    @include for-mobile {
      height: 100vh;
      width: 100vw;
    }
  }
  .container {
    position: relative;
    height: 100vh;
    @include for-mobile {
      width: 100vw;
    }
    &__image {
      width: calc(100vw - 10rem);
      height: 100vh;
      object-fit: contain;
      @include for-mobile {
        width: 100vw;
      }
    }
    .infoNote {
      width: 100%;
      top: 4vh;
      position: absolute;
      // background-color: var(--c-warning);
      background: linear-gradient(
        90deg,
        rgba(28, 28, 13, 0) 15%,
        rgba(236, 199, 19, 1) 40%,
        rgba(236, 199, 19, 1) 50%,
        rgba(236, 199, 19, 1) 60%,
        rgba(101, 99, 48, 0) 85%
      );
      color: var(--c-light);
      height: 1.5rem;
      padding: var(--spacer-sm) var(--spacer-lg);
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      &__icon {
        margin-right: var(--spacer-xs);
      }
    }
  }

  .controls {
    padding: var(--spacer-sm);
    border: 2px solid var(--c-light);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    &:hover {
      background-color: rgba(245, 241, 237, 0.35);
    }
    &__disabled {
      opacity: 0.5;
      cursor: default !important;
      &:hover {
        background-color: transparent !important;
      }
    }
  }

  .navbar {
    @include for-mobile {
      width: calc(100% - 4.5rem);
      padding: 0 var(--spacer-base) 0;
      top: 10vh;
    }
    position: absolute;
    top: 10vh;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 11.5rem);
    height: 4rem;
    padding: 0 var(--spacer-2xl);
    margin: 0 0.75rem;
    z-index: 1;
  }
  .close-btn {
    @include for-mobile {
      flex: 1 1 calc(20% - 2rem);
      padding: var(--spacer-xl) var(--spacer-sm);
    }
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: var(--spacer-xs) var(--spacer-sm);
    transition: all 0.15s ease-in-out;
    // border-radius: 6px;
    width: 6.5rem;
    &:hover {
      background-color: rgba(245, 241, 237, 0.35);
    }
    &__icon {
      background: rgba(19, 17, 18, 0.25);
      padding: 0.75rem;
      border-radius: 50%;
    }
    &__text {
      filter: drop-shadow(1px 1px 2px var(--c-dark));
    }
    span {
      @include for-mobile {
        flex: 1 1 60%;
      }
      margin-left: var(--spacer-xs);
      font-size: var(--font-sm);
      text-transform: uppercase;
    }
  }
  .dummy {
    visibility: hidden;
    width: 6.5rem;
    font-size: var(--font-sm);
    padding: 0 var(--spacer-sm);
    @include for-mobile {
      flex: 1 1 20%;
    }
  }
  .image-name {
    position: absolute;
    bottom: 10vh;
    left: 50%;
    height: 3rem;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
      padding: var(--spacer-2xs) var(--spacer-xl);
      filter: drop-shadow(1px 1px 2px var(--c-dark));
      margin: 0;
      text-align: center;
      b {
        color: var(--c-primary);
      }
    }
  }
}
.counter {
  padding: var(--spacer-2xs) var(--spacer-xl);
  filter: drop-shadow(1px 1px 2px var(--c-dark));
}
</style>
