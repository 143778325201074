<template>
  <div class="a-gallery-grid" :class="`type-${images.length}`">
    <div
      v-if="images.length === 1"
      class="container"
      :class="`type-${images.length}`"
    >
      <img
        :src="getImage(images[0])"
        :alt="images[0].name"
        :ref="images[0].name"
        @click="$emit('select-image', images[0].name)"
      />
    </div>
    <div
      v-if="images.length === 2"
      class="container"
      :class="`type-${images.length}`"
    >
      <div v-for="image in images" :key="image.name" class="img-container">
        <img
          :src="getImage(image)"
          :alt="image.name"
          :ref="image.name"
          @click="$emit('select-image', image.name)"
        />
      </div>
    </div>
    <div
      v-if="images.length === 3"
      class="container"
      :class="`type-${images.length}`"
    >
      <div class="img-container">
        <div class="img-container__section">
          <img
            :src="getImage(images[0])"
            :alt="images[0].name"
            :ref="images[0].name"
            @click="$emit('select-image', images[0].name)"
          />
        </div>
        <div class="img-container__section">
          <img
            :src="getImage(images[1])"
            :alt="images[1].name"
            :ref="images[1].name"
            @click="$emit('select-image', images[1].name)"
          />
        </div>
      </div>
      <div class="container__hero">
        <img
          :src="getImage(images[2])"
          :alt="images[2].name"
          :ref="images[2].name"
          @click="$emit('select-image', images[2].name)"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    images: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    getImage(image) {
      if (image) {
        return require(`@/assets/gallery/${image.room}/${image.name}-webp.webp`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-gallery-grid {
  display: flex;
  flex-wrap: wrap;

  img {
    transition: all 0.15s ease-in-out;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
}
.container {
  overflow: hidden;
  margin: 0 0 var(--spacer-xs);
}

.type-1 {
  width: 100%;
  .container {
    @include for-mobile {
      height: 16rem;
    }
    height: 30rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.type-2 {
  .container {
    @include for-mobile {
      height: 8rem;
    }
    display: flex;
    height: 15rem;
    width: 100%;

    .img-container {
      flex: 1 1 calc(50% - 0.25rem);
      max-width: calc(50% - 0.25rem);
      &:first-child {
        margin-right: var(--spacer-2xs);
      }
      &:last-child {
        margin-left: var(--spacer-2xs);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
.type-3 {
  .container {
    @include for-mobile {
      height: 16rem;
    }
    display: flex;
    flex-wrap: wrap;
    height: 30rem;
    width: 100%;
    &__hero {
      flex: 1 1 50%;
      max-width: calc(50% - 0.25rem);
      margin-left: var(--spacer-2xs);
    }

    .img-container {
      @include for-mobile {
        height: 16rem;
      }
      display: flex;
      flex-direction: column;
      flex: 1 1 calc(50% - 0.25rem);
      max-width: calc(50% - 0.25rem);
      margin-right: var(--spacer-2xs);
      height: 30rem;
      &__section {
        @include for-mobile {
          height: calc(8rem - 0.25rem);
        }
        flex: 1 1 50%;
        height: calc(15rem - 0.25rem);
        &:first-child {
          margin-bottom: var(--spacer-2xs);
        }
        &:last-child {
          margin-top: var(--spacer-2xs);
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
