<template>
  <div class="a-logo" @click="goToHome">
    <img
      :src="
        logo === 'dark'
          ? require('@/assets/logos/s1_b_logo.png')
          : require('@/assets/logos/s1_w_logo.png')
      "
    />
  </div>
</template>
<script>
export default {
  props: {
    logo: {
      type: String,
      default: "dark",
    },
  },
  methods: {
    goToHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style lang="scss" scoped>
.a-logo {
  height: 4rem;
  cursor: pointer;
  img {
    width: auto;
    height: 4rem;
    user-select: none;
  }
}
</style>
